import { ChainsEnum, CORRECT_CHAIN } from '@/constants';

export const MOVECOIN =
  '0xe4497a32bf4a9fd5601b27661aa0b933a923191bf403bd08669ab2468d43b379::move_coin::MoveCoin';
export const BTC =
  CORRECT_CHAIN.id === ChainsEnum['lumio-testnet']
    ? '0x45a6dce5d868431d7f0c224a0122e64372eeb1697309529c9aaa94a97e9d331e::coins::BTC'
    : '0x43417434fd869edee76cca2a4d2301e528a1551b1d719b75c350c3c97d15b8b9::coins::BTC';
export const USDC =
  '0x808b4ffe04011cd20327a910518b4bff661f73fa907e9fc41ad690f84fa6f83e::asset::USDC';
export const USDT =
  CORRECT_CHAIN.id === ChainsEnum['lumio-testnet']
    ? '0x45a6dce5d868431d7f0c224a0122e64372eeb1697309529c9aaa94a97e9d331e::coins::USDT'
    : '0x43417434fd869edee76cca2a4d2301e528a1551b1d719b75c350c3c97d15b8b9::coins::USDT';
export const DAI =
  '0xb4d7b2466d211c1f4629e8340bb1a9e75e7f8fb38cc145c54c5c9f9d5017a318::coins_extended::DAI';
export const ETH =
  '0xb4d7b2466d211c1f4629e8340bb1a9e75e7f8fb38cc145c54c5c9f9d5017a318::coins_extended::ETH';
export const WETH =
  '0x808b4ffe04011cd20327a910518b4bff661f73fa907e9fc41ad690f84fa6f83e::asset::WETH';
export const USDD =
  '0xf22bede237a07e121b56d91a491eb7bcdfd1f5907926a9e58338f964a01b17fa::asset::USDD';
export const DOODOO =
  '0x73eb84966be67e4697fc5ae75173ca6c35089e802650f75422ab49a8729704ec::coin::DooDoo';

export const LSD =
  '0x53a30a6e5936c0a4c5140daed34de39d17ca7fcae08f947c02e979cef98a3719::coin::LSD';

export const LZ_USDC =
  '0xf22bede237a07e121b56d91a491eb7bcdfd1f5907926a9e58338f964a01b17fa::asset::USDC';

export const LZ_USDT =
  '0xf22bede237a07e121b56d91a491eb7bcdfd1f5907926a9e58338f964a01b17fa::asset::USDT';

export const WH_USDC =
  '0x5e156f1207d0ebfa19a9eeff00d62a282278fb8719f4fab3a586a0a2c0fffbea::coin::T';
export const WH_USDT =
  '0xa2eda21a58856fda86451436513b867c97eecb4ba099da5775520e0f7492e852::coin::T';

export const AM_APT =
  '0x111ae3e5bc816a5e63c2da97d0aa3886519e0cd5e4b046659fa35796bd11542a::amapt_token::AmnisApt';

export const UPTOS =
  '0x4fbed3f8a3fd8a11081c8b6392152a8b0cb14d70d0414586f0c9b858fcd2d6a7::UPTOS::UPTOS';

// refers to wallet-manager-backend/src/config/const.ts
// only these tokens have a price
export const knownTokens = [
  'btc',
  'eth',
  'apt',
  'usdc',
  'usdt',
  'weth',
  'dai',
  'wbtc',
  'bnb',
  'aptoge',
  'mojo',
  'stapt',
  'sol',
  'cake',
  'tapt',
  'thl',
  'abel',
  'alt',
  'amapt',
  'mbx',
  'props',
  'doodoo',
  'gari',
  'returd',
  'shrimp',
  'apts',
  'uptos',
  'chewy',
  'lsd',
  'mkl',
];
